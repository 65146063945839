import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { AuthmodalserviceService } from '../../auth-comp/authmodal/service/authmodalservice.service';

@Component({
  selector: 'app-landingmenu',
  templateUrl: './landingmenu.component.html',
  styleUrls: ['./landingmenu.component.scss'],
})
export class LandingmenuComponent implements OnInit {
  @Input() fixedMenu = false;
  pathname = '';

  constructor(
    private router: Router,
    public authModalService: AuthmodalserviceService,
    public authservice: AuthApiserviceService,
  ) {
    this.checkUserToken();
    router.events.subscribe((val) => {
      this.getpathname();
    });
  }

  ngOnInit() {}

  async checkUserToken() {
    //Refresh The Function to get the active token The token will help us to know when to show the Login/Sign Up on the Landing  Menu

    this.authservice.onLoad();
  }

  getpathname() {
    this.pathname = window.location.pathname;
  }
}
