/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthApiserviceService,
  IUserData,
} from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-signincomp',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  authData: IUserData = {};
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  async logout() {}

  async proceed() {
    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.authservice
      .login(this.myForm.value)
      .subscribe(
        (res) => {
          console.log(res);
          this.authData = res;
          this.authservice.storeLoginToken(this.authData.data?.token);
          this.authservice.storeUserdetails(this.authData.data?.credentials);
          this.generalService.presentToast('Login Successful');
          this.popStatusCode(200);
          this.navigate('dashboard');
        },
        (err) => {
          //console.log(err);
          this.popStatusCode(err.status);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }
}
