/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthApiserviceService,
  IUserData,
} from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-signupcomp',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  authData: IUserData = {};
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phonenumber: ['', [Validators.required, Validators.minLength(11)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassWord: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  async logout() {}

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;

    // Remove the Confirm Password Property is not necessary
    delete formValue.confirmPassWord;

    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.authservice
      .signUp(this.generalService.getFormData(formValue))
      .subscribe(
        (res) => {
          console.log(JSON.stringify(res));
          this.authData = res;
          this.authservice.storeLoginToken(this.authData.data?.token);
          this.authservice.storeUserdetails(this.authData.data?.credentials);
          this.generalService.presentToast('Your Account Has being created');
          this.popStatusCode(200);
          this.navigate('dashboard');
        },
        (err) => {
          console.log(err);
          this.popStatusCode(err.status);
          this.generalService.presentToast(err.error?.message);
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }
}
