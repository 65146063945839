/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';
import { Device } from '@capacitor/device';
import { Token_Key, User_Key } from './appconst';
import { GeneralserviceService } from './generalservice.service';

let apiBaseEndPoint = '';

const STAGING = 'https://api.nexostakingbet.com.ng';
const REMOTE_HOST = 'https://api.nexostakingbet.com.ng';

apiBaseEndPoint = STAGING;
// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const refreshLoginRoute = `${apiBaseEndPoint}/api/v1/auth/sessions-login?refresh=`;
const loginroute = `${apiBaseEndPoint}/api/v1/auth/login`;
const signUpRoute = `${apiBaseEndPoint}/api/v1/auth/signup`;
const checkEmailRoute = `${apiBaseEndPoint}/api/v1/auth/forgot-password/send-email`;
const resetPasswordRoute = `${apiBaseEndPoint}/api/v1/auth/forgot-password/confirm`;

const updateProfileRoute = `${apiBaseEndPoint}/api/v1/auth/update-details`;
const updatePasswordRoute = `${apiBaseEndPoint}/api/v1/auth/reset-password`;
const updateDpdRoute = `${apiBaseEndPoint}/api/v1/auth/update-dp`;

@Injectable({
  providedIn: 'root',
})
export class AuthApiserviceService {
  authorization = '';
  header: any = {};
  resetPasswordToken = '';
  userDetails: IUserDetails = null;

  constructor(
    private httpClient: HttpClient,
    public generalService: GeneralserviceService
  ) {
    // eslint-disable-next-line max-len
    // This is used to refresh Tokens and User details everytime this services is called or the page when this service is used is force Reload
    this.onLoad();
  }

  async onLoad() {
    // Load Default Headers for most of the Api
    this.header = await this.getheader();
    //this.loginToken = await this.getLoginToken();
    this.userDetails = await this.getUserdetails();

    await this.checkSession();
  }

  loginRefresh(): Observable<IUserData> {
    return this.httpClient.get(refreshLoginRoute, {
      headers: this.header,
    });
  }

  login(body: ILoginData): Observable<IUserData> {
    return this.httpClient.post<IUserData>(loginroute, body, {
      headers: this.header,
    });
  }

  signUp(body: FormData): Observable<IUserData> {
    return this.httpClient.post<IUserData>(signUpRoute, body, {
      headers: this.header,
    });
  }

  checkEmail(body: any): Observable<any> {
    return this.httpClient.post(checkEmailRoute, body, {
      headers: this.header,
    });
  }

  resetPassword(body: any): Observable<any> {
    body.token = this.resetPasswordToken;
    return this.httpClient.post(resetPasswordRoute, body, {
      headers: this.header,
    });
  }

  updateProfile(body: IUpdateProfileData): Observable<any> {
    return this.httpClient.post(updateProfileRoute, body, {
      headers: this.header,
    });
  }

  updatePassword(body: IUpdatePasswordData): Observable<any> {
    return this.httpClient.post(updatePasswordRoute, body, {
      headers: this.header,
    });
  }

  updateDp(body: any): Observable<any> {
    return this.httpClient.post(updateDpdRoute, body, {
      headers: this.header,
      responseType: 'json',
      reportProgress: true, // this is used to show the progress of upload
      observe: 'events', // this will help to print the refresh all observable including the progress
    });
  }

  /**
   *  This section are use to store User/Login Details and Token
   */

  async storeLoginToken(loginToken: any) {
    await Storage.set({
      key: Token_Key,
      value: loginToken,
    });
    // This update the Header with the Latest Token
    this.header = await this.getheader();
  }

  async storeUserdetails(userDetails: any) {
    await Storage.set({
      key: User_Key,
      value: JSON.stringify(userDetails),
    });
    // This update the Header with the User Details
    this.userDetails = await this.getUserdetails();
  }

  async logout() {
    await Storage.remove({ key: Token_Key });
    await Storage.remove({ key: User_Key });
    //  Set the latest state of the Auth Token and User Details
    this.header = await this.getheader();
    this.userDetails = await this.getUserdetails();
  }

  /**
   *  End of storage section
   */

  /**
   *  This section are use to get login details headers
   */

  async getUserdetails() {
    const { value } = await Storage.get({ key: User_Key });
    return JSON.parse(value);
  }

  async getLoginToken() {
    const { value } = await Storage.get({ key: Token_Key });
    return value;
  }

  async getheader() {
    const info = await Device.getId();
    this.authorization = await this.getLoginToken();

    const headers = {
      uuid: info.uuid,
      Authorization: 'Bearer ' + this.authorization + '',
    };
    return headers;
  }

  /**
   *  JWT Session
   */
  decodeJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  async checkSession() {
    if (!this.authorization) {
      return;
    }
    const jwt: IJwtResponse = this.decodeJWT(this.authorization);
    const expDate = new Date(jwt.exp * 1000);
    const curDate = new Date();

    if (curDate > expDate) {
      this.generalService.presentToast('Your Session Has Expired');
      this.generalService.navigatePage('/logout');
      this.logout();
    }
    // console.log(expDate);
  }
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface ISignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  otherNames: string;
  phoneNumber: string;
}

export interface IUpdateProfileData {
  firstName: string;
  lastName: string;
  otherNames: string;
  phoneNumber: string;
  jobTitle: string;
  bio: string;
}

export interface IUpdatePasswordData {
  oldPassword: string;
  newPassword: string;
}

export interface IUserData {
  message?: string;
  data?: {
    token: string;
    credentials: IUserDetails;
  };
}

export interface IUserDetails {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  dpUrl: string;
  email: string;
  phoneNumber: string;
  emailVerified: boolean;
  activeDeposits: number;
  totalEarned: number;
  totalWithdrawn: string;
  accountBalance: number;
  cryptoInfo: CryptoInfo;
  siteCryptoInfo: SiteCryptoInfo;
}

export interface CryptoInfo {
  btc: any;
  doge: any;
  eth: any;
  usdt: any;
}

export interface SiteCryptoInfo {
  btc: string;
  doge: string;
  eth: string;
  usdt: string;
}

export interface IJwtResponse {
  userId: string;
  uuid: string;
  token: string;
  nbf: number;
  exp: number;
  iat: number;
}
