import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { AuthmodalserviceService } from '../../auth-comp/authmodal/service/authmodalservice.service';
import { LandingmobileserviceService } from './service/landingmobileservice.service';

@Component({
  selector: 'app-landingmenumobile',
  templateUrl: './landingmenumobile.component.html',
  styleUrls: ['./landingmenumobile.component.scss'],
})
export class LandingmenumobileComponent implements OnInit {
  pathname = '';

  constructor(
    private router: Router,
    public authModalService: AuthmodalserviceService,
    public authservice: AuthApiserviceService,
    public landingMenuMobile: LandingmobileserviceService,
  ) {
    router.events.subscribe((val) => {
      this.getpathname();
    });
  }

  ngOnInit() {}

  async checkUserToken() {
    //Refresh The Function to get the active token The token will help us to know when to show the Login/Sign Up on the Landing  Menu

    this.authservice.onLoad();
  }

  getpathname() {
    this.pathname = window.location.pathname;
  }

  /* Set the width of the sidebar to 250px (show it) */
  openNav() {
    console.log('Open Menu');
    document.getElementById('mySidepanelbar').style.width = '100%';
    document.getElementById('mySidepanel').style.width = '300px';
  }

  /* Set the width of the sidebar to 0 (hide it) */
  closeNav() {
    console.log('Close Menu');
    document.getElementById('mySidepanelbar').style.width = '0';
    document.getElementById('mySidepanel').style.width = '0';
  }
}
