import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-setnewpassword',
  templateUrl: './setnewpassword.component.html',
  styleUrls: ['./setnewpassword.component.scss'],
})
export class SetnewpasswordComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassWord: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  async logout() {}

  async proceed() {
    // Get Value from the form in form of an object
    let formValue: any = this.myForm.value;

    // Remove the Confirm Password Property is not necessary
    delete formValue.confirmPassWord;

    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.authservice
      .resetPassword(formValue)
      .subscribe(
        (res) => {
          //console.log(res);
          this.generalService.presentToast('Your Password Has being Changed');
          this.popStatusCode(res['status']);
          this.navigate('login');
        },
        (err) => {
          //console.log(err);
          this.popStatusCode(err['status']);
          this.generalService.presentToast(err.error?.message);
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }
}
