import { Injectable } from '@angular/core';
import {
  AlertController,
  NavController,
  ToastController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GeneralserviceService {
  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public navctrl: NavController
  ) {}

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
    });

    await toast.present();
  }

  async presentBasicAlert(header, subheader, message, handlerRef) {
    const alert = await this.alertController.create({
      header,
      subHeader: subheader,
      message,
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await alert.present();
  }

  async presentBasicAlertDialog(
    header,
    subheader,
    message,
    btnSuccesName: string,
    btnCancelName: string,
    successcallBack: () => void,
    cancelcallBack: () => void
  ) {
    const alert = await this.alertController.create({
      header,
      subHeader: subheader,
      message,
      buttons: [
        {
          text: btnSuccesName,
          role: 'okay',
          handler: successcallBack,
        },
        {
          text: btnCancelName,
          role: 'cancel',
          handler: cancelcallBack,
        },
      ],
    });
    await alert.present();
  }

  getFormData(data: any) {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key) => {
      formData.append(key, data[key] ?? '');
    });

    return formData;
  }

  navigatePage(reDirectionPage) {
    this.navctrl.navigateRoot(reDirectionPage);
  }
}
