import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './guards/authguard.guard';
import { LoginguardGuard } from './guards/loginguard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/home/home.module').then(
        (m) => m.HomePageModule
      ),
    canLoad: [AuthguardGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/landingpage/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'authpage',
    loadChildren: () =>
      import('./pages/auth/authpage/authpage.module').then(
        (m) => m.AuthpagePageModule
      ),
    canLoad: [LoginguardGuard],
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/dashboard/logout/logout.module').then(
        (m) => m.LogoutPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/dashboard/profile/profile.module').then(
        (m) => m.ProfilePageModule
      ),
    canLoad: [AuthguardGuard],
  },
  {
    path: 'order-history',
    loadChildren: () =>
      import('./pages/dashboard/order-history/order-history.module').then(
        (m) => m.OrderHistoryPageModule
      ),
    canLoad: [AuthguardGuard],
  },
  {
    path: 'profile-manage',
    loadChildren: () =>
      import('./pages/dashboard/profile-manage/profile-manage.module').then(
        (m) => m.ProfileManagePageModule
      ),
    canLoad: [AuthguardGuard],
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/landingpage/faq/faq.module').then((m) => m.FaqPageModule),
  },
  {
    path: 'testpage',
    loadChildren: () =>
      import('./pages/landingpage/testpage/testpage.module').then(
        (m) => m.TestpagePageModule
      ),
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/landingpage/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/landingpage/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'withdrawal',
    loadChildren: () => import('./pages/dashboard/withdrawal/withdrawal.module').then( m => m.WithdrawalPageModule)
  },
  {
    path: 'deposit',
    loadChildren: () => import('./pages/dashboard/deposit/deposit.module').then( m => m.DepositPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
